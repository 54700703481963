<template>
  <component :is="'script'" v-html="jsonld" type="application/ld+json" />
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { mapGetters } from 'vuex';
import { htmlDecode } from '@vue-storefront/core/filters';

export default {
  name: 'JsonLdCategory',
  props: {
    productsTotal: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      getCategoryProducts: 'category-next/getCategoryProducts',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent',
      getAggregations: 'category-extension/getAggregations'
    }),
    jsonld () {
      return JSON.stringify(this.content, null, 2);
    },
    content () {
      return {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        'name': htmlDecode(this.getBreadcrumbsCurrent),
        'offers': {
          '@type': 'AggregateOffer',
          'offerCount': this.productsTotal,
          'lowPrice': this.minPrice,
          'highPrice': this.maxPrice,
          'priceCurrency': currentStoreView().i18n.currencyCode
        }
      }
    },
    minPrice () {
      const priceAggregation = this.getAggregations.agg_price;
      const priceMinValue = priceAggregation?.filter?.price_min?.value;

      return priceMinValue !== null ? parseFloat(priceMinValue).toFixed(2) : 0;
    },
    maxPrice () {
      const priceAggregation = this.getAggregations.agg_price;
      const priceMaxValue = priceAggregation?.filter?.price_max?.value;

      return priceMaxValue !== null ? parseFloat(priceMaxValue).toFixed(2) : 0;
    }
  }
}
</script>

<template>
  <component :is="'script'" v-html="jsonld" type="application/ld+json" />
</template>

<script>
import { htmlDecode } from '@vue-storefront/core/filters';
import { mapGetters } from 'vuex';

export default {
  name: 'JsonLdBreadcrumb',
  computed: {
    ...mapGetters({
      breadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent',
      getCurrentRoute: 'url/getCurrentRoute',
      baseUrl: 'siteData/baseUrl'
    }),
    jsonld () {
      return JSON.stringify(this.content, null, 2);
    },
    content () {
      return {
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        'itemListElement': this.itemList
      }
    },
    itemList () {
      const homePageBreadcrumbs = {
        '@type': 'ListItem',
        'position': 1,
        'item':
          {
            '@id': this.baseUrl,
            'name': this.$i18n.t('Homepage')
          }
      };

      const breadcrumbsListElement = this.breadcrumbsRoutes.map((route, idx) => ({
        '@type': 'ListItem',
        'position': idx + 2,
        'item':
          {
            '@id': this.baseUrl + route.route_link,
            'name': htmlDecode(route.name)
          }
      }));

      const currentPageBreadcrumbs = {
        '@type': 'ListItem',
        'position': breadcrumbsListElement.length + 2,
        'item':
          {
            '@id': this.baseUrl + this.getCurrentRoute.path,
            'name': this.getBreadcrumbsCurrent
          }
      };

      return [
        homePageBreadcrumbs,
        ...breadcrumbsListElement,
        currentPageBreadcrumbs
      ]
    }
  }
}
</script>

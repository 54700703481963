/* eslint-disable */
const defaultCfg = {
    threshold: 0,
    root: null,
    rootMargin: '0px'
}

function update (el: any, ctx: any, value: any) {
    let handler, cfg, changed

    if (typeof value === 'function') {
        handler = value
        cfg = defaultCfg
        changed = ctx.cfg === void 0
    } else {
        handler = value.handler
        cfg = Object.assign({}, defaultCfg, value.cfg)
        changed = ctx.cfg === void 0
    }

    if (ctx.handler !== handler) {
        ctx.handler = handler
    }

    if (changed === true) {
        ctx.cfg = cfg
        ctx.observer !== void 0 && ctx.observer.unobserve(el)

        ctx.observer = new IntersectionObserver(([ entry ]) => {
            if (typeof ctx.handler === 'function') {

                const res = ctx.handler(entry, ctx.observer)

                if (
                    res === false ||
                    (ctx.once === true && entry.isIntersecting === true)
                ) {
                    destroy(el)
                }
            }
        }, cfg)

        ctx.observer.observe(el)
    }
}

function destroy (el: any) {
    const ctx = el.__qvisible

    if (ctx !== void 0) {
        ctx.observer !== void 0 && ctx.observer.unobserve(el)
        delete el.__qvisible
    }
}

export default {
    name: 'intersect',

    inserted (el: any, { modifiers, value }: any) {
        if (el.__qvisible !== void 0) {
            destroy(el)
            el.__qvisible_destroyed = true
        }

        const ctx = {
            once: modifiers.once === true
        }

        update(el, ctx, value)

        el.__qvisible = ctx
    },

    update (el: any, binding: any) {
        const ctx = el.__qvisible
        ctx !== void 0 && update(el, ctx, binding.value)
    },

    unbind (el: any) {
        if (el.__qvisible_destroyed === void 0) {
            destroy(el)
        }
        else {
            delete el.__qvisible_destroyed
        }
    }
}

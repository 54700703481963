<template>
  <div class="faq-block" itemscope="" itemtype="https://schema.org/FAQPage">
    <SfOAccordion
      :multiple="true"
      :open="open"
      transition=""
      :show-chevron="false"
      :first-open="false"
      class="accordion"
      id="sf-accordion"
    >
      <SfOAccordionItem
        v-for="faq in questions"
        :key="faq.id"
        :header="faq.title"
        itemscope="" itemprop="mainEntity" itemtype="https://schema.org/Question"
      >
        <div class="accordion-item-text" itemscope="" itemprop="acceptedAnswer" itemtype="https://schema.org/Answer" v-html="faq.answer" />
      </SfOAccordionItem>
    </SfOAccordion>
  </div>
</template>

<script>
import SfOAccordion from 'theme/components/storefront-override/SfOAccordion/SfOAccordion';

export default {
  name: 'OFAQ',
  components: {
    SfOAccordion
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    open () {
      return this.questions.map((faq) => faq.title)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';

.faq-block {
  margin: 0 auto;
  padding: 0 var(--spacer-10);
  max-width: 1178px;
  font-size: var(--spacer-13);
  line-height: var(--spacer-19);
  color: var(--dark-gray);
  text-align: justify;

  h3 {
    margin-bottom: var(--spacer-6);
  }

  p {
    margin-top: var(--spacer-6);
    font-size: var(--spacer-14);
  }

  .sf-link {
    font-weight: var(--font-medium);
    color: var(--orange);
    display: inline-block;
  }

  &__rules {
    margin-top: var(--spacer-14);
  }
}

::v-deep {
  .content {
    padding: 0;

    & > header {
      display: none;
    }
  }

  #accordion {
    display: none;
  }

  .title {
    font-size: var(--font-size-20);
    font-weight: 500;
    line-height: var(--spacer-24);
    margin: var(--spacer-13) auto var(--spacer-10);
    max-width: 790px;
    padding-left: var(--spacer-10);
    width: 100%;

    @include for-desktop {
      margin: var(--spacer-20) auto var(--spacer-30);
      font-size: var(--font-size-32);
      line-height: var(--spacer-32);
      padding-left: 0;
    }
  }

  .sf-accordion-item {
    box-sizing: border-box;
    margin: var(--spacer-10) auto;
    padding: 0 var(--spacer-10);
    width: 100%;
    position: relative;

    &:last-child {
      margin-bottom: var(--spacer-50);

      @include for-desktop {
        margin-bottom: var(--spacer-80);
      }
    }

    @include for-desktop {
      margin: var(--spacer-12) auto;
      padding: 0;
    }

    .sf-accordion-item__header {
      background: var(--light-gray);
      font-size: var(--font-size-14);
      line-height: var(--spacer-24);
      padding: var(--spacer-15) 0 var(--spacer-15) var(--spacer-15);
      text-align: left;
      padding-right: var(--spacer-40);
      position: relative;

      @include font-icon(var(--icon-shape));

      &:before {
        position: absolute;
        right: 0;
        font-size: var(--font-size-12);
        margin: var(--spacer-15);
        height: var(--spacer-13);
        transition: transform 0.2s ease;
        width: var(--spacer-12);

        @media (min-width: $tablet-min) {
          margin-right: var(--spacer-36);
        }
      }

      &.sf-accordion-item__header--open {
        background: transparent;
        border: 1px solid var(--gray3);
        border-bottom: none;
        box-sizing: border-box;
        padding-bottom: var(--spacer-10);

        &:before {
          transform: rotate(180deg);
          transition: transform 0.2s ease;
        }

        @include for-desktop {
          padding-bottom: var(--spacer-25);
        }
      }

      @include for-desktop {
        font-size: var(--font-size-18);
        line-height: var(--spacer-18);
        padding: var(--spacer-30) 0 var(--spacer-30) var(--spacer-30);
      }
    }

    .sf-accordion-item__content {
      box-sizing: border-box;
      border: 1px solid var(--gray3);
      border-top: none;
    }

    .accordion-item-text {
      font-size: var(--font-size-14);
      font-weight: 400;
      line-height: var(--spacer-24);
      margin: 0 var(--spacer-15) var(--spacer-15);

      @include for-desktop {
        margin: 0 var(--spacer-30) var(--spacer-30);
      }

      p {
        margin: 0 0 15px;
      }
    }
  }
}
</style>

export const exclusionUrlsListUa = [
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_offertypefreshmeat=8592',
    h1: 'М\'ясо на вагу',
    title: 'М\'ясо на вагу - купити вагове м\'ясо в Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_offertypefreshmeat=4182',
    h1: 'Фасоване м\'ясо',
    title: 'М\'ясо фасоване - купити м\'ясо фасоване в Києві | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8599',
    h1: 'Яловичина',
    title: 'Купити яловичину - ціна на свіжу яловичину | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8787',
    h1: 'Індичка',
    title: 'Купити індичку - ціна на м\'ясо індички в Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8723',
    h1: 'М\'ясо кроля',
    title: 'Купити м\'ясо кроля - ціни на крольчатину в Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=4183',
    h1: 'М\'ясо курки',
    title: 'Купити м\'ясо курки - ціна на куряче м\'ясо у Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8593',
    h1: 'М\'ясо перепіла',
    title: 'Купити перепелине м\'ясо - ціна перепелиного м\'яса в Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8597',
    h1: 'Свинина',
    title: 'Купити свинину – ціна на м\'ясо свинини в Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8646',
    h1: 'М\'ясо качки',
    title: 'Купити качки м\'ясо - ціна на качине м\'ясо в Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=9195',
    h1: 'Сало',
    title: 'Купити сало - ціна на сало у Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8624',
    h1: 'М\'ясо для стейків',
    title: 'Купити стейки - ціна на м\'ясо для стейків з доставкою у Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8662',
    h1: 'Суповий набір',
    title: 'Купити набір суповий - ціна на набір для супу | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8259',
    h1: 'Філе',
    title: 'Купити філе - ціна м\'яса філе | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8613',
    h1: 'Ескалоп',
    title: 'Купити ескалоп - ціна ескалопу | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8658',
    h1: 'М\'ясо стегна',
    title: 'Купити м\'ясо стегна - стегнова частина м\'яса | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8604',
    h1: 'Вирізка',
    title: 'Вирізка - купити м\'ясну вирізку в Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8630',
    h1: 'Гомілка',
    title: 'Купити гомілка - ціна гомілок у магазині | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8260',
    h1: 'Грудинка',
    title: 'Купити грудинку ціна на м\'ясо грудинку в інтернет-магазині | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8659',
    h1: 'Крильця',
    title: 'Купити крильця - ціна на крильця в Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=4185',
    h1: 'Печінка',
    title: 'Купити печінку - ціна на печінку в інтернет-магазині | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=10080',
    h1: 'Бекон',
    title: 'Купити бекон - ціна свіжого бекону | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=8026',
    h1: 'Кебаб',
    title: 'Купити кебаб ціна на кебаб з доставкою | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=7838',
    h1: 'Котлети',
    title: 'Купити котлети ціна на котлети напівфабрикат | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=7832',
    h1: 'Мітболи',
    title: 'Купити мітболи - ціна на мітболи | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=9263',
    h1: 'М\'ясний рулет',
    title: 'Купити м\'ясні рулети - ціна на рулети м\'ясні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=8699',
    h1: 'Мариноване м\'ясо',
    title: 'Купити м\'ясо мариноване - ціна маринованого м\'яса з доставкою в Києві та Україні | VARUS'
  },
  {
    url: '/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=3967',
    h1: 'Фарш',
    title: 'Купити фарш ціна на фарш з доставкою в Києві та Україні | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?countrymanufacturerforsite=11072',
    h1: 'Ковбаса іспанська',
    title: 'Купити іспанську ковбасу - ціна на іспанські ковбаси | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=5582',
    h1: 'Ковбаски в упаковці',
    title: 'Ковбаски в упаковці - купити упаковку ковбасок | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=8352',
    h1: 'Ковбаса кільце',
    title: 'Ковбаса кільце - купити кільце ковбаси | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=3330',
    h1: 'Нарізана ковбаса',
    title: 'Нарізана ковбаса - купити нарізку ковбаси | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=5258',
    h1: 'Палиця ковбаси',
    title: 'Купити палицю ковбаси - ціна палиці ковбаси | VARU'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=5423',
    h1: 'Варена ковбаса',
    title: 'Купити варену ковбасу - ціна вареної ковбаси у Києві | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=11971',
    h1: 'Варено-копчена ковбаса',
    title: 'Варено-копчена ковбаса - ціна ковбаси варено-копченої | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=7201',
    h1: 'Копчена ковбаса',
    title: 'Купити ковбасу копчену - ціна на копчені ковбаси | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=6481',
    h1: 'Кров\'янка',
    title: 'Купити кров\'янку - ціна на кров\'яну ковбасу у Києві та Україні | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=5583',
    h1: 'Напівкопчена ковбаса',
    title: 'Напівкопчена ковбаса - купити напівкопчені ковбаси у Києві | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=6611',
    h1: 'Ковбаси сиров\'ялені',
    title: 'Ковбаси сиров\'ялені - купити сиров\'ялені ковбаси в Україні | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=3331',
    h1: 'Сирокопчена ковбаса',
    title: 'Купити сирокопчену ковбасу - ціна на сирокопчену ковбасу в Україні | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=7743',
    h1: 'Брауншвейзька ковбаса',
    title: 'Брауншвгейська ковбаса - ціна на ковбасу брауншвгейську | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=5427',
    h1: 'Лікарська ковбаса',
    title: 'Купити докторську ковбасу - ціна на ковбасу докторську | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=6806',
    h1: 'Дрогобицька ковбаса',
    title: 'Дрогобицька ковбаса - купити дрогобицьку ковбасу | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=7570',
    h1: 'Кабаноси',
    title: 'Купити кабаноси - ціна на кабаноси у Києві та Україні | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=7648',
    h1: 'Молочна ковбаса',
    title: 'Купити молочну ковбасу - ціна молочної ковбаси | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=6486',
    h1: 'Московська ковбаса',
    title: 'Купити московську ковбасу - ціна московської ковбаси | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=6745',
    h1: 'Ковбаса президентська',
    title: 'Ковбаса президентська - ціна президентської ковбаси VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=3333',
    h1: 'Салямі',
    title: 'Купити салямі - ціна на ковбасу салямі | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=5260',
    h1: 'Сервелат',
    title: 'Купити сервелат - ціна на сервелат | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=8932',
    h1: 'Ковбаса столична',
    title: 'Ковбаса столична - купити столичну ковбасу | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_additivessausage=14524',
    h1: 'Ковбаса з перцем',
    title: 'Ковбаса з перцем - купити ковбасу з перцем | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_additivessausage=3335',
    h1: 'Ковбаса із салом',
    title: 'Ковбаса з салом - купити ковбасу з салом | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_additivessausage=6612',
    h1: 'Ковбаса із сиром',
    title: 'Ковбаса з сиром - купити ковбасу з сиром | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_featuressausage=3900',
    h1: 'Ковбаса без глютену',
    title: 'Ковбаса без глютена - купити ковбасу без глютена у Києві | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_featuressausage=5826',
    h1: 'Ковбаса дитяча',
    title: 'Дитяча ковбаса - купити дитячу ковбасу | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/kovbasi?forsausages_shelltypesausage=6610',
    h1: 'Натуральні ковбаси',
    title: 'Натуральні ковбаси - купити натуральну ковбасу | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/sosiski-ta-sardelki?forfrankfurters_producttypefrankfurters=3579',
    h1: 'Сосиски',
    title: 'Купити сосиски - ціна на сосиски в Україні, Києві | VARUS'
  },
  {
    url: '/kolbasy-sosiski-delikatesy/sosiski-ta-sardelki?forfrankfurters_producttypefrankfurters=8834',
    h1: 'Сардельки',
    title: 'Купити сардельки - ціна на сардельки в Україні | VARUS'
  },
  {
    url: '/frukti-ovochi-gorihi/ovochi-svizhi?forvegetables_typevegetables=8544',
    h1: 'Авокадо',
    title: 'Купити авокадо - ціна на авокадо у Києві та Україні | VARUS'
  },
  {
    url: '/frukti-ovochi-gorihi/ovochi-svizhi?forvegetables_typevegetables=6177',
    h1: 'Капуста',
    title: 'Купити капусту - ціна на капусту в Києві та Україні | VARUS'
  },
  {
    url: '/frukti-ovochi-gorihi/ovochi-svizhi?forvegetables_typevegetables=5882',
    h1: 'Картопля',
    title: 'Купити картоплю - ціна на картоплю в Україні | VARUS'
  }
]

export const exclusionUrlsListRu = [
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_offertypefreshmeat=8592',
    h1: 'Мясо на развес',
    title: 'Мясо на развес - купить весовое мясо в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_offertypefreshmeat=4182',
    h1: 'Фасованное мясо',
    title: 'Фасованное мясо - купить мясо фасованное в Киеве | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8599',
    h1: 'Говядина',
    title: 'Купить говядину - цена на мясо говядины в Киеве и Украине с доставкой | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8787',
    h1: 'Индейка',
    title: 'Купить индейку - цена на мясо индюшатины в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8723',
    h1: 'Мясо кролика',
    title: 'Купить мясо кролика - цена на крольчатину в Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=4183',
    h1: 'Мясо курицы',
    title: 'Купить мясо курицы - цена на куриное мясо в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8593',
    h1: 'Мясо перепела',
    title: 'Купить мясо перепела - цена перепелиного мяса в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8597',
    h1: 'Свинина',
    title: 'Купить свинину - цена на мясо свинины в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_kindfreshmeat=8646',
    h1: 'Мясо утки',
    title: 'Купить мясо утки - цена на утиное мясо в Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=9195',
    h1: 'Сало',
    title: 'Купить сало - цена на сало в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8624',
    h1: 'Мясо для стейков',
    title: 'Купить стейки - цена на мясо для стейков с доставкой в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8662',
    h1: 'Суповой набор',
    title: 'Купить суповой набор - цена на набор для супа | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8259',
    h1: 'Филе',
    title: 'Купить филе - цена на мясо филе | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_producttypefreshmeat=8613',
    h1: 'Эскалоп',
    title: 'Купить эскалоп - цена на эскалоп | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8658',
    h1: 'Мясо бедра',
    title: 'Купить мясо бедра - бедренная часть мяса | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8604',
    h1: 'Вырезка',
    title: 'Вырезка - купить мясную вырезку в Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8630',
    h1: 'Голень',
    title: 'Купить голень - цена голеней в магазине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8260',
    h1: 'Грудинка',
    title: 'Купить грудинку - цена на мясо грудинку в интернет-магазине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=8659',
    h1: 'Крылышки',
    title: 'Купить крылышки - цена на крылышки в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/myaso-svizhe?forfreshmeat_partfreshmeat=4185',
    h1: 'Печень',
    title: 'Купить печень - цена на печень в интернет-магазине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=10080',
    h1: 'Бекон',
    title: 'Купить бекон - цена на свежий бекон | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=8026',
    h1: 'Кебаб',
    title: 'Купить кебаб - цена на кебаб с доставкой | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=7838',
    h1: 'Котлеты',
    title: 'Купить котлеты - цена на котлеты полуфабрикат | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=7832',
    h1: 'Митболы',
    title: 'Купить митболы - цена на митболы | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=9263',
    h1: 'Мясной рулет',
    title: 'Купить мясной рулет - цена на рулеты мясные | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=8699',
    h1: 'Маринованное мясо',
    title: 'Купить маринованное мясо - цена маринованного мяса с доставкой в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/myasni-virobi-ta-yaycya/napivfabrikati-myasni?formeatproducts_kindmeatproducts=3967',
    h1: 'Фарш',
    title: 'Купить фарш - цена на фарш с доставкой в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?countrymanufacturerforsite=11072',
    h1: 'Колбаса испанская',
    title: 'Купить колбасу испанскую - цена на испанские колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=5582',
    h1: 'Колбаски в упаковке',
    title: 'Колбаски в упаковке - купить упаковку колбасок | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=8352',
    h1: 'Колбаса кольцо',
    title: 'Колбаса кольцо - купить кольцо колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=3330',
    h1: 'Нарезанная колбаса',
    title: 'Нарезанная колбаса - купить нарезку колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_packingtypesausage=5258',
    h1: 'Палка колбасы',
    title: 'Купить палку колбасы - цена палки колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=5423',
    h1: 'Вареная колбаса',
    title: 'Купить вареную колбасу - цена колбасы вареной в Киеве | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=11971',
    h1: 'Варено-копченая колбаса',
    title: 'Варено-копченая колбаса - цена колбасы варено-копченой | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=7201',
    h1: 'Копченая колбаса',
    title: 'Купить копченую колбасу - цена на копчёные колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=6481',
    h1: 'Кровянка',
    title: 'Купить кровянку - цена на кровяную колбасу в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=5583',
    h1: 'Полукопченая колбаса',
    title: 'Полукопченая колбаса - купить полукопчёные колбасы в Киеве | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=6611',
    h1: 'Колбасы сыровяленые',
    title: 'Колбасы сыровяленые - купить сыровяленые колбасы в Украине | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_typesausage=3331',
    h1: 'Сырокопченая колбаса',
    title: 'Купить сырокопченую колбасу - цена на сырокопченые колбасы в Украине | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=7743',
    h1: 'Брауншвейгская колбаса',
    title: 'Брауншвгейская колбаса - цена на колбасу брауншвгейскую | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=5427',
    h1: 'Докторская колбаса',
    title: 'Купить докторскую колбасу - цена на колбасу докторскую | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=6806',
    h1: 'Дрогобычская колбаса',
    title: 'Дрогобычская колбаса - купить дрогобычскую колбасу | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=7570',
    h1: 'Кабаносы',
    title: 'Купить кабаносы - цена на кабаносы в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=7648',
    h1: 'Молочная колбаса',
    title: 'Купить молочную колбасу - цена молочной колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=6486',
    h1: 'Московская колбаса',
    title: 'Купить московскую колбасу - цена московской колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=6745',
    h1: 'Колбаса президентская',
    title: 'Колбаса президентская - цена президентской колбасы | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=3333',
    h1: 'Салями',
    title: 'Купить салями - цена на колбасу салями | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=5260',
    h1: 'Сервелат',
    title: 'Купить сервелат - цена на сервелат | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_varietysausage=8932',
    h1: 'Колбаса столичная',
    title: 'Колбаса столичная - купить столичную колбасу | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_additivessausage=14524',
    h1: 'Колбаса с перцем',
    title: 'Колбаса с перцем - купить колбасу с перцем | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_additivessausage=3335',
    h1: 'Колбаса с салом',
    title: 'Колбаса с салом - купить колбасу с салом | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_additivessausage=6612',
    h1: 'Колбаса с сыром',
    title: 'Колбаса с сыром - купить колбасу с сыром | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_featuressausage=3900',
    h1: 'Колбаса без глютена',
    title: 'Колбаса без глютена - купить колбасу без глютена в Киеве | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_featuressausage=5826',
    h1: 'Колбаса детская',
    title: 'Колбаса детская - купить детскую колбасу | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/kovbasi?forsausages_shelltypesausage=6610',
    h1: 'Натуральные колбасы',
    title: 'Натуральные колбасы - купить натуральную колбасу | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/sosiski-ta-sardelki?forfrankfurters_producttypefrankfurters=3579',
    h1: 'Сосиски',
    title: 'Купить сосиски - цена на сосиски в Украине, Киеве | VARUS'
  },
  {
    url: '/ru/kolbasy-sosiski-delikatesy/sosiski-ta-sardelki?forfrankfurters_producttypefrankfurters=8834',
    h1: 'Сардельки',
    title: 'Купить сардельки - цена на сардельки в Украине | VARUS'
  },
  {
    url: '/ru/frukti-ovochi-gorihi/ovochi-svizhi?forvegetables_typevegetables=8544',
    h1: 'Авокадо',
    title: 'Купить авокадо - цена на авокадо в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/frukti-ovochi-gorihi/ovochi-svizhi?forvegetables_typevegetables=6177',
    h1: 'Капуста',
    title: 'Купить капусту - цена на капусту в Киеве и Украине | VARUS'
  },
  {
    url: '/ru/frukti-ovochi-gorihi/ovochi-svizhi?forvegetables_typevegetables=5882',
    h1: 'Картошка',
    title: 'Купить картошку - цена на картофель с доставкой в Киеве и Украине | VARUS'
  }
]

<template>
  <div class="varus-cafe-categories">
    <div class="varus-cafe-categories-filter">
      <div class="varus-cafe-categories-filter__list">
        <div
          v-for="category in categories"
          :key="category.id"
          class="varus-cafe-categories-filter__item"
          :class="{ 'varus-cafe-categories-filter__item--active': activeCategory(category.slug) }"
          @click="$emit('selectCategory', category.slug)"
          ref="categoryItems"
        >
          <div
            class="varus-cafe-categories-filter__block"
          >
            <img
              v-if="category.slug.length"
              class="varus-cafe-categories-filter__image"
              loading="lazy"
              :src="getCategoryPreviewImage(category.pimcore_id)"
              :alt="category.name"
            >
            <span class="varus-cafe-categories-filter__name">{{ category.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="slug === 'pica'"
      class="varus-cafe-categories-filter__list varus-cafe-categories-filter__list--additives"
    >
      <div
        class="varus-cafe-categories-filter__item"
        :class="{ 'varus-cafe-categories-filter__item--active': isPizzaIngridientsActive }"
        @click="togglePizzaIngredientsFilter()"
      >
        <div
          class="varus-cafe-categories-filter__block"
        >
          <span class="varus-cafe-categories-filter__name">{{ $t('Additives for pizza') }}</span>
          <SfIcon
            v-if="isPizzaIngridientsActive"
            class="close-icon"
            icon="cross"
            size="12px"
            color="secondary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCafeCategoryImagePath } from 'theme/helpers';
import { SfIcon } from '@storefront-ui/vue';

export default {
  name: 'CategoriesFilter',
  components: {
    SfIcon
  },
  props: {
    categories: {
      type: Array,
      required: true
    },
    slug: {
      type: String,
      default: ''
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getCategoryPreviewImage (itemId) {
      return getCafeCategoryImagePath(itemId)
    },
    activeCategory (slug) {
      return this.slug === slug
    },
    scrollToActiveCategory () {
      this.$nextTick(() => {
        const activeItem = this.$refs.categoryItems.find(item => item.classList.contains('varus-cafe-categories-filter__item--active'));
        if (activeItem) {
          activeItem.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
        }
      });
    },
    togglePizzaIngredientsFilter () {
      this.$emit('togglePizzaIngredientsFilter');
    }
  },
  computed: {
    isPizzaIngridientsActive () {
      return this.query.hasOwnProperty('pizzaIngredient');
    }
  },
  mounted () {
    this.scrollToActiveCategory();
  },
  watch: {
    slug (newValue, oldValue) {
      if (newValue === oldValue) return;

      this.scrollToActiveCategory();
    }
  }
}
</script>

<style lang="scss" scoped>
.varus-cafe-categories {
  padding: 10px 0;
}
.varus-cafe-categories-filter {
  overflow: auto;
  touch-action: pan-x;

  @media only screen and (min-width: 1024px) {
    touch-action: initial;
    overflow: initial;
  }

  &__list {
    display: flex;
    gap: 8px;
    padding: 0 10px;
    flex-wrap: nowrap;

    @media (min-width: 1024px) {
      gap: 10px;
      padding: 0 10px;
      flex-wrap: wrap;
    }

    &::after {
      content: '';
      flex: 0 0 2px;
    }

    &--additives {
      margin: 8px 0 0;

      @media (min-width: 1024px) {
        margin: 10px 0 0;
      }
    }
  }

  &__item {
    box-sizing: border-box;
    background: var(--white);
    padding: 8px;
    flex: 0 0 auto;
    border-radius: 5px;
    display: flex;
    align-items: center;

    @media (min-width: 1024px) {
      &:hover {
        cursor: pointer;
      }
    }

    &--active {
      box-shadow: 0 0 0 2px var(--orange, #FF6000) inset;
    }
  }

  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__image {
    background: var(--white);
    width: 30px;
  }

  &__name {
    color: var(--black, #333);
    text-align: center;
    font-family: var(--font-family-primary), serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
.close-icon {
  &:before {
    font-size: 16px;
  }

  ::v-deep &.sf-icon {
    top: -2px;
  }
}
</style>

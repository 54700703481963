<template>
  <div class="m-category-np">
    <div class="m-category-np__logo">
      <span class="m-category-np__icon"></span>
    </div>
    <div class="m-category-np__wrapper">
      <span class="m-category-np__title">{{ title }}</span>
      <span class="m-category-np__description">
          {{ $t('NP notice description') }}

          <button
            class="m-category-np__button"
            @click="openShippingModal"
          >
            {{ $t('NP notice description 2') }}
          </button>

          {{ $t('NP notice description 3') }}
        </span>
    </div>
  </div>
</template>
<script>
import ShippingControlsMixin from '$modules/shipping/mixins/shipping-controls-mixin';

export default {
  name: 'MCategoryNP',
  mixins: [ShippingControlsMixin],
  props: {
    isProduct: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    title () {
      return this.$t(
        this.isProduct ? 'NP notice title product' : 'NP notice title'
      )
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-category-np {
  display: flex;
  gap: var(--spacer-25);
  padding: var(--spacer-12) var(--spacer-25);
  background: var(--yellow-light-grayish);

  &__button {
    display: contents;
    cursor: pointer;
    color: var(--orange);
    transition: 200ms;
    font-size: var(--font-size-14);

    &:hover {
      color: var(--orange-hover);
    }
  }

  &__logo {
    flex: 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    @include font-icon(var(--icon-info));

    &:before {
      font-size: var(--font-size-40);
      color: var(--orange);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-5);
  }

  &__title, &__description {
    display: block;
    font-size: var(--font-size-14);
  }

  &__title {
    color: var(--black);
  }

  &__description {
    color: var(--dark-gray);
  }
}
</style>

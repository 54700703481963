import { ModalList } from '../store/ui/modals';

export default {
  name: 'Delivery',
  computed: {
    hasError () {
      return this.error.shopNotFound ||
          this.error.deliveryNotFound ||
          this.error.deliveryPointNotFound
    }
  },
  methods: {
    async openShippingModal (payload = null) {
      const modalData = {
        name: ModalList.ShippingModal
      }

      if (payload) modalData.payload = payload

      await this.$store.dispatch('ui/hideBottomNavigation', true)
      await this.$store.dispatch('ui/openModal', modalData)
    }
  }
};

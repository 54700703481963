<template>
  <div>
    <div :class="classList" v-if="isLoading && isPlaceholderEnabled">
      <AProductPlaceholder
        v-for="index in initPlaceholderCount"
        :key="index"
        class="m-category-list__item placeholder-item-svg"
      />
    </div>
    <div v-else :class="classList">
      <SfOProductCard
        v-if="loading"
        v-for="(product) in products"
        :product="product"
        :key="product.id"
        :title="product.title"
        :image="product.image"
        :regular-price="product.price.regular"
        :special-price="product.price.special"
        :link="product.link"
        :is-new-post="isNewPost"
        link-tag="router-link"
        :product-quantity="product.quantityText"
        :badge-label="badgeLabel"
        :badge-color="badgeColor"
        class="m-category-list__item"
        :wishlist-icon="wishlistIcon"
        :alt-name="makeAlt(product)"
        :lazy="isLazy()"
      />
    </div>
  </div>
</template>

<script>
import SfOProductCard from 'theme/components/storefront-override/SfOProductCard';
import AProductPlaceholder from 'theme/components/atoms/a-product-placeholder';
import { mapGetters } from 'vuex';

export default {
  name: 'MCategoryList',
  components: {
    SfOProductCard,
    AProductPlaceholder
  },
  props: {
    altName: {
      type: String,
      default: ''
    },
    isPlaceholderEnabled: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    placeholderCount: {
      type: Number,
      default: 32
    },
    lastRowClass: {
      type: Boolean,
      default: false
    },
    badgeLabel: {
      type: String,
      default: ''
    },
    badgeColor: {
      type: String,
      default: ''
    },
    classList: {
      type: String,
      default: ''
    },
    wishlistIcon: {
      type: Boolean,
      default: true
    },
    products: {
      type: Array,
      default: () => []
    },
    isAllLazy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getClickLoadMore: 'category-extension/getClickLoadMore',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    initPlaceholderCount () {
      const countProduct = this.products.length;

      return countProduct > 0 ? countProduct : this.placeholderCount;
    },

    loading () {
      return this.getClickLoadMore === true ? this.getClickLoadMore : !this.isLoading
    }
  },
  methods: {
    makeAlt (product) {
      if (isNaN(product.index) || !this.altName) {
        return product.title
      }

      const index = product.index + 1

      return `Фото ${index} - ${this.altName}`
    },
    isLazy () {
      return this.isAllLazy;
    }
  }
};
</script>

<style lang="scss">
@import "~theme/css/breakpoints";

.m-category-list {
  &__item {
    --product-card-max-width: 50%;
    margin-bottom: var(--spacer-30);
    padding: 0 var(--spacer-5) var(--spacer-20);
    box-sizing: border-box;
    max-width: 50%;
    width: 100%;
    position: relative;

    &:after {
      content: none;
    }

    @media (max-width: $mobile-max) {
      &:nth-child(2n):not(:last-child) {
        &:after {
          display: block;
          content: '';
          width: calc(100vw - var(--spacer-20));
          height: 1px;
          background-color: var(--color-zircon);
          position: absolute;
          top: auto;
          bottom: 0;
          left: auto;
          right: 0;
          z-index: -1;
          transition: none;
          box-shadow: none;
        }
      }
    }

    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      &:nth-child(3n):not(:last-child) {
        &:after {
          display: block;
          content: '';
          width: calc(100vw - var(--spacer-45));
          height: 1px;
          background-color: var(--color-zircon);
          position: absolute;
          top: auto;
          bottom: 0;
          left: auto;
          right: 0;
          z-index: -1;
          transition: none;
          box-shadow: none;
        }
      }
    }

    @media (min-width: $desktop-min) {
      &:nth-child(4n):not(:last-child) {
        &:after {
          display: block;
          content: '';
          width: calc(100vw - 260px);
          max-width: 950px;
          height: 1px;
          background-color: var(--color-zircon);
          position: absolute;
          top: auto;
          bottom: 0;
          left: auto;
          right: 0;
          z-index: -1;
          transition: none;
          box-shadow: none;
        }
      }
    }

    @include for-tablet {
      max-width: calc(100% / 3);
      --product-card-max-width: calc(100% / 3);
    }

    @include for-desktop {
      max-width: 25%;
      width: 25%;
      --product-card-max-width: 25%;
      padding-bottom: 0;
    }

    ::v-deep {
      @media (max-width: $mobile-max) {
        .sf-product-card__title {
          min-height: px2rem(32);
          --product-card-title-font-size: var(--font-size-13);
          --product-card-title-font-line-height: var(--font-base);
        }
      }

      .sf-product-card__price {
        .sf-price__old {
          color: var(--dark-gray);
          font-size: var(--font-sm);
          font-weight: var(--font-normal);
        }
        .sf-price__special {
          color: var(--orange);
          font-size: var(--font-lg);
          font-weight: var(--font-medium);
        }
      }
      .a-add-to-cart {
        width: 3.625rem;
        height: 2.5rem;
      }
      .a-product-quantity {
        width: 8.375rem;

        .sf-quantity-selector__button {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    &:hover {
      ::v-deep {
        .a-product-quantity {
          display: flex;
        }
      }
    }
  }
}
</style>
